.img_logo {
  object-fit: cover;
  width: 200px;
  height: auto;
  @media only screen and (max-width: 1200px) {
    width: 160px;
    height: auto;
  }
  @media only screen and (max-width: 1089px) {
    width: 150px;
    height: auto;
  }
}
.logo_en {
  margin-left: -35px;
}
.logo_ar {
  margin-right: -35px;
}
