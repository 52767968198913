.data-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 6px 12px;
  border-radius: 6px;
  background: rgba(196, 196, 196, 0.2);

  .daily_rent_tooltip{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  .data-title {
    color: #04c23a;    

    font-size: 10.5px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    justify-self: center;
    @media only screen and (max-width: 900px) {
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 15.4px; /* 154% */
    }
  }
  .no-available-title {
    color: #626687;
    font-size: 10.5px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    justify-self: center;
    @media only screen and (max-width: 900px) {
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 15.4px; /* 154% */
    }
  }
  .not_available {
    color: red;
  }
  .data-subtitle {
    color: #626687;
    text-align: right;

    font-size: 7.5px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    justify-self: center;
  }
  .data-per-year {
    color: var(--dark-blue, #1b1c57);
    text-align: right;
    font-size: 10.5px;
    font-style: normal;
    font-weight: 700;
    line-height: 16.5px; /* 157.143% */
    @media only screen and (max-width: 900px) {
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 15.4px; /* 154% */
    }
  }
}
